import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Grid } from '@material-ui/core';
import Bar from '../../components/bar';
import Image from "../../components/image"
import "./testimonial.css"

const Testimonial = () => {

    var items = [
        {
            name: "Dr. Nair Anup C, Bishop Moore College",
            description: "I came with my students (National Service Scheme, Bishop Moore College) and my family. It was an eye opener for me and I believe for all others. The campus is extremely well kept, the infrastructure superb and the staff extremely cordial and caring. Hope this service to humanity continues forever. ",
        },
        {
            name: "Gerry Hamblet, England",
            description: "This is my third visit to Jyothis – I love it here! The children are so happy and I am sure that is to do with the wonderful and lovely staff. We did crafts and coloring- the children’s favorite. May the school continue to grow and improve. ",
        },
        {
            name: "V.V David, Mavelikkara",
            description: "Had a wonderful time with these blessed kids. Highly appreciate the ways these kids are trained and the patience with which the teachers train them.",
        },
        {
            name: "Jayaraj M.K, Principal, State Institute for Mentally Challenged, Pangappara",
            description: "The infrastructure facilities of this institution are tremendous and remarkable. There are almost all rehabilitation facilities arranged here including modern digital technology. This institute can play a pivotal role in the effective mode of rehabilitation for children with mental challenges in Kerala. I extend my hearty wishes.",
        },
        {
            name: "Heather Sinclair",
            description: "Thank you so much for allowing us and welcoming us into this wonderful school. I have enjoyed my time with the children over the past few weeks and cannot believe how quickly the time goes- especially when playing with the children! I will take so many lovely memories back to England. ",
        }
    ]

    return (
        <div style={{ alignItems: "center", textAlign: "center", paddingTop: 30 }}>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Grid container spacing={3} style={{ paddingBottom: 20 }} alignItems="center" direction="column">
                    <Typography style={{ display: "block" }} variant="h5">Testimonials</Typography>
                    <Bar />
                </Grid>
            </Box>
            <div className="testimonials">
                <li>
                    <div className='testimonial-image-div' style={{ padding: 0, maxHeight: 100, maxWidth: 200 }}>
                        <Image alt='bishpos (1).jpg' filename='bishpos (1).jpg' />
                    </div>
                    <p>
                        <i>
                            “That the Good Shepherd, Our Lord,<br />
                            May bless you all,<br />
                            That He may be with you,<br />
                            In this beautiful project,<br />
                            That He may abide with you,<br />
                            That He may abide with you,<br />
                            That He may abide with you,<br />
                            In Jesus Christ committed to one another”
                        </i>
                    </p>
                    <h1>Joris Vercammen, Archbishop of Utrecht (Visitor)</h1>
                </li>
            </div>
            {
                items.map((item, i) => <Item key={i} item={item} />)
            }
        </div>
    )
}

function Item(props) {
    return (
        <div className="testimonials">
            <li>
                <div className='testimonial-image-div' style={{ padding: 0, maxHeight: 100, maxWidth: 200 }}>
                    <Image alt='avatar_skeleton.jpg' filename='avatar_skeleton.jpg' />
                </div>
                <Typography>{props.item.description}</Typography>
                <h1>{props.item.name}</h1>
            </li>
        </div>
    )
}

export default Testimonial