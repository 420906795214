import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Testimonial from "../components/testimonial/testimonial"

const TestimonialsPage = () => {
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Layout>
                    <SEO title="Testimonials" />
                    <Testimonial />
                </Layout>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default TestimonialsPage
